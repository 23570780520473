.footer {
    background-color: #273C44;
    height: 300%;
    color: white;
    padding: 50px;   
}

.footer-items{
    display: flex;
    justify-content: space-around;
}

.footer-item h5{
    color: #F7A92D;
}

.footer-item i {
    margin-right: 5px;
}

.footer-item a{
    text-decoration: none;
    color: white;
}

.footer-item a:hover{
    color: #F7A92D;
}

.footer-item{
    display: flex;
    flex-direction: column;
}

.footer-item:nth-child(1){
    display: inline;
    max-width: 350px;
}

.footer-item:nth-child(3){
    margin-left: 50px;
}

.footer-item:nth-child(2){
    margin-left: 50px;
}

@media (max-width: 1000px){
    .footer-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer-item {
        margin-top: 20px;
        justify-content: center;
    }

    .footer-item:nth-child(2){
        margin-left: -45px;
    }

    .footer-item:nth-child(3){
        margin-left: -160px;
    }

    .footer-item:nth-child(4){
        margin-left: -160px;
    }
}

@media (max-width: 768px) {
    .footer-item:nth-child(2){
        margin-left: -30px;
    }

    .footer-item:nth-child(3){
        margin-left: -160px;
    }

    .footer-item:nth-child(4){
        margin-left: -160px;
    }
}


