.documents-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.document-item{
    margin: 50px auto;
    width: 80%;
}

.links-container{
    display: none;
}

@media (max-width: 490px){
    .documents-container{
        display: none;
    }
    .links-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 20px;
    }
}