.faq-content{
    margin: 100px auto;
    width: 80%;
    margin-bottom: 50px;
}

.accordion-button{
    background-color: #F7A92D;
    color: black;  
}

.accordion-button:not(.collapsed){
    background-color: #F7A92D;
    color: white; 
}

svg{
    fill: white;
}