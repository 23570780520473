.main-content{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-content img{
    margin: 20px;
    width: 50%;
}

.title{
    text-align: center;
    text-transform: uppercase;
}

.main-content-form{
    width: 60%;
    background-color: #F7A92D;
    /*height: 80vh;*/
    margin-bottom: 50px;
}

.main-content-form h2{
    text-align: center;
    text-transform: uppercase;
    color: white;
}

.main-content-form h5{
    margin-top: 20px;
    color: white;
    text-align: center;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    width: 100%;
}

.group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.group-text{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
}

.icon img{
    margin-left: -5px;
    width: 40px;
}

.group-picture-km{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
}

.group-equipamentos{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
}

.group-personal{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.btn{
    margin-top: 5px;
    float: right;
}

.modal-loading{
    display: flex;
    justify-content: center;
}

.modal-body{
    display: flex;
}

@media only screen and (max-width: 640px){
    .main-content img{
        margin: 20px;
        width: 100%;
    }
    .main-content-form{
        width: 80%;
    }
    .group{
        flex-direction: column;
    }
    .group-text{
        flex-direction: column;
    }
    .group-personal{
        flex-direction: column;
    }
    .group-picture-km{
        flex-direction: column;
        width: 100%;
    }
    .group-equipamentos{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .icon img{
        width: 40px;
    }
}

@media only screen and (max-width: 1024px){
    .main-content-form{
        width: 80%;
    }
}

@media only screen and (max-width: 1332px){
    .group-picture-km{
        flex-direction: column;
        width: 83%;
    }

    .group-text input{
        width: 100%;
    }
}

