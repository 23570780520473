.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title img{
  width: 300px;
  margin-left: -70px;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (max-width: 430px){
  .main-navigation__title img{
    margin-right: 90px;
  }
}

@media (max-width: 380px){
  .main-navigation__title img{
    margin-right: 30px;
  }
}

@media (max-width: 325px){
  .main-navigation__title img{
    margin-right: -5px;
  }
  .main-navigation__menu-btn{
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }

  .main-navigation__title{
    margin-left:100px;
  }
}