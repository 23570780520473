.card-content {
  margin: 135px auto;
  background-color: #f7a92d;
  width: 90%;
}

.card-content h1 {
  text-align: center;
  color: white;
}

.content {
  display: flex;
  flex-direction: row;
}

.map-content {
  width: 720px;
  height: 450px;
}

.contactos {
  width: 50%;
  margin: auto auto;
}

.content h3 {
  text-align: center;
  color: white;
}

.icon-contact img {
  width: 50px;
}

.sobre {
  color: white;
}

.subtitulo {
  text-align: center;
  color: black;
}

.cookies {
  text-align: center;
  color: black;
  margin-top: 10px;
}

@media (max-width: 320px) {
  .card-content {
    margin: 0 auto;
  }
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .map-content {
    width: 150px;
    height: 150px;
  }

  .contactos {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .map-content {
    width: 300px;
    height: 300px;
  }

  .contactos {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .map-content {
    width: 500px;
    height: 500px;
  }
}

@media only screen and (max-width: 1024px) {
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /*.map-content{
        width: 500px;
        height: 500px;
    }*/
}
