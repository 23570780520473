.label{
    color: white;
}

.input-field{
    width: 120%;
}

@media only screen and (max-width: 430px){
    .input-field{
        width: 100%;
    }
}

@media only screen and (max-width: 768px){
    .input-field{
        width: 100%;
    }
}
