.side-drawer{
    position: fixed;
    /*left: 0;*/
    right: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 74%;
    background: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}